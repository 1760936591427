import {ChatCraftProvider} from "../../ChatCraftProvider";

export const FREEMODELPROVIDER_API_URL = "https://chat.umamiai.xyz";
export const FREEMODELPROVIDER_NAME = "umamiai";
const FREEMODELPROVIDER_DEFAULT_MODEL = "gpt-4o";

export class FreeModelProvider extends ChatCraftProvider {
  constructor() {
    super(
      FREEMODELPROVIDER_NAME,
      FREEMODELPROVIDER_API_URL,
      FREEMODELPROVIDER_DEFAULT_MODEL,
      "82a02524a4064b33ab04c4a11392e031"
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async queryModels(key: string) {
    const res = await fetch("/models", { headers: { Accept: "application/json" }, method: "GET" });

    // const res = await fetch(`${FREEMODELPROVIDER_API_URL}/models`, {
    //   method: "GET",
    // });

    if (!res.ok) {
      throw new Error(`${res.status} ${await res.text()}`);
    }

    try {
      const result = await res.json();
      let models: string[] = [];
      for (let model of result.data) {
        if (model.type === "chat-completion") {
          models.push(model.id);
        }
      }
      //
      // Object.entries(result).forEach((entry) => {
      //   const [key, value] = entry;
      //   models.push(key);
      //   console.log(`${key}: ${value}`);
      // });
      // Don't sort, since models are already returned in order of usefulness for free provider.
       // Using Set and spread operator (ES6)
        return [...new Set(models)];
      //return result.data.map((model: { id: string }) => model.id) as string[];
    } catch (err: any) {
      throw new Error(`error querying models API: ${err.message}`);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async validateApiKey(key: string) {
    return true;
  }
}
